@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
}

body img {
  object-fit: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
