.card {
  transition: all 1s linear;
  cursor: pointer;
}

.card__primary {
  width: 350px;
}

.card__secondary {
  display: flex;
}

.card:hover {
  opacity: 0.8;
}

.card .card__image {
  object-fit: cover;
}

.card__primary .card__image {
  width: 350px !important;
  height: 200px !important;
}

.card__secondary .card__image {
  width: 100%;
  height: 100%;
  border-radius: 8px 0 0 8px !important;
}

.card__secondary .ant-card-cover {
  min-width: 40%;
  width: 40%;
}

.card__secondary .ant-card-meta-title,
.card__secondary .ant-card-meta-description {
  white-space: pre-wrap;
  text-overflow: clip;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 1000px) {
  .card__secondary .ant-card-body {
    padding: 12px 16px;
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%;
  }

  .card__primary .card__image {
    width: 100% !important;
    height: 180px !important;
  }

  .card__secondary .ant-card-body {
    padding: 12px;
  }
}
