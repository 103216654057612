.card-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 480px) {
  .card-list .ant-space-item {
    width: 100%;
  }
}
