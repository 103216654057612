.article--card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.article--card .ant-card-body {
  flex: 1;
}
