.ql-clipboard {
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
    opacity: 0;
}

.quillWrapper {
    overflow: unset !important;
    height: 500px !important;
}

.ql-container {
    height: 600px !important;
}